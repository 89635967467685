.OidcResponseHandler {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #5F63D4;
    color: #FFF;
    font-size: 1em;
    padding: 8em;
    padding-top: 10em;
    text-align: center;
    cursor: pointer;
}