.LoginView {
  background: #5f63d4;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  letter-spacing: 0.05em;
}

.LoginView .logo {
  display: block;
  width: 20rem;
  margin: 0 auto 1rem;
  margin-top: 5%;

}

.LoginView .bigger-container {
  padding-top:5%;
  padding-right:2.8rem;
  padding-left:2.8rem;
  padding-bottom:5%;
}

.LoginView .container {
  background: #fff;
  box-shadow: 0px 2px 8px 2px rgba(6, 2, 25, 0.25);
  padding: 1.2rem;
  max-height: 100%;
  max-width: 100%;
}

.LoginView h1 {
  color: #5f63d4;
  font-size: 1.3rem;
  text-align: center;
}

.LoginView p {
  font-size: 1.1rem;
  text-align: left;
}

.LoginView input {
  background-color: #E8E8E8;
  border: none;
  display: block;
  font-size: 0.9em;
  padding: 10px;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
  width: 240px;
  letter-spacing: 0.05em;
}

.LoginView input:focus {
  outline: none;
}

.LoginView button {
  background: #5f63d4;
  border: none;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: "Nunito", sans-serif;
  font-size: 2.1vh;
  height: 6vh;
  margin: 0 auto;
  outline: none;
  width: 35%;
  letter-spacing: 0.05em;
}

.LoginView .betaspace {
  width: 100%;
  height: 2.2em;
}