.active .HomeSidebarCategoriesView {
  display: none;
  visibility: hidden;
}

.HomeSidebarCategoriesView {
  display: flex;
  flex-direction: column;
  font-size: 0.5em;
  font-weight: 100;
  color: #55596B;
  letter-spacing: 0.08em;
  width: 85%;
  min-width: 224px;
  margin: 0 auto;
  margin-top: 40px;
}

.HomeSidebarCategoriesView .category {
  display: flex;
  align-items: center;
  background-color: #F6F6F6;
  cursor: pointer;
  padding: 1em;
  margin-bottom: 1em;
}

.HomeSidebarCategoriesView .category:hover {
  background-color: #EEE;
}

.HomeSidebarCategoriesView .category .text {
  margin-left: 1em;
}

@media (min-width: 1400px) {
  .HomeSidebarCategoriesView {
    font-size: 0.7em;
  }

  .HomeSidebarCategoriesView .category {
    margin-top: 0.8em;
    padding: 1.4em;
  }
}
