.HomeHeaderView {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HomeHeaderView .logo {
  width: 220px;
}

.HomeHeaderView .logo::selection {
  color: #F2F2F2;
  background-color: #5F63D4;
}

.HomeHeaderView a {
  width: 24px;
}

.HomeHeaderView a img {
  width: 100%;
  vertical-align: middle;
}

.HomeHeaderView .hamburger {
  width: 24px;
  cursor: pointer;
  visibility: hidden;
}

@media (max-width: 800px) {
  .HomeHeaderView .hamburger {
    visibility: visible !important;
  }
}
