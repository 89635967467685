* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;

  font-family: "Nunito", sans-serif;
}

.hidden {
  display: none !important;
}

::-webkit-scrollbar {
    display: none;
}
