.SettingsView .column {
  display: inline-block;
  height: 100vh;
  overflow-y: scroll;
  padding: 48px;
  vertical-align: top;
}

.SettingsView .left {
  width: 25vw;
  float: left;
}

.SettingsView .left a .logo {
  width: 150px;
}

.SettingsView .left a {
  color: #5f63d4;
  text-decoration: none;
}

.SettingsView .headertop {
  margin-bottom: 2em;
}

.SettingsView .headertop a {
  font-size:  0.9em;
  text-decoration: none;
}

.SettingsView .left ul {
  list-style-type: none;
  color: #B0B9C7;
}

.SettingsView .left li {
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0.5em;
  transition: 0.25s ease all;
}

.SettingsView .left li:hover {
  color: #5f64d4;
}

.SettingsView .left li.active {
  color: #5f64d4;
}

.SettingsView .right {
  background: #F9F9F9;
  width: 75vw;
  float: right;
}

@media (max-width: 800px) {
  .SettingsView .left {
    display: block;
    width: 100vw;
    height: 100%;
  }

  .SettingsView .right {
    display: block;
    width: 100vw;
    height: 100%;
  }
}
