.PreferencesView {
}

.PreferencesView .container {
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, 0%);
}

.PreferencesView .container h1 {
  font-size: 1.3em;
  color: #5f63d4;
  margin-bottom: 1.5em;
  letter-spacing: 0.03em;
  text-align: center;
}

.PreferencesView .category {
  font-size: 0.9em;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: none;
  margin-bottom: 1em;
  background-color: #F2F2F2;
  color: #55596B;
  border-right: 1em solid #F9F9F9;
  letter-spacing: 0.02em;
  padding: 1em 2em;
  width: 50%;
}

.PreferencesView .category:hover {
  background-color: #FFFFFF;
}

.PreferencesView .text {
  margin-left: 1em;
}

.PreferencesView h3 {
  font-size: 0.9em;
}

.PreferencesView p {
  font-size: 0.9em;
}

.PreferencesView .btn-pref {
  background: #5f63d4;
  border: 2px solid #5f63d4;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: "Nunito", sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin: 24px auto 0;
  margin-top: 1em;
  outline: none;
  padding: 6px 20px;
  transition: 0.25s ease all;
}

.PreferencesView .btn-pref:hover {
  background: rgba(0, 0, 0, 0);
  color: #5f63d4;
}

@media (max-width: 800px) {
  .PreferencesView {
    height: 100%;
  }

  .PreferencesView .container {
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, 0%);
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .PreferencesView .category {
    width: 100%;
  }
}
