.active .HomeSidebarEventModal {
  margin-top: 0px;
}

.HomeSidebarEventModal {
  display: inline-block;
  margin-top: 48px;
  vertical-align: top;
  width: 100%;
}

.HomeSidebarEventModal img {
  margin-bottom: 8px;
  max-width: 100%;
}

.HomeSidebarEventModal h2 {
  font-size: 1em;
  font-weight: 400;
  color: #55596B;
  letter-spacing: 0.05em;
  margin-top: 10px;
  margin-bottom: 6px;
}

.HomeSidebarEventModal p {
  font-size: 0.9em;
  font-weight: 400;
  color: #55596B;
  letter-spacing: 0.05em;
}


.HomeSidebarEventModal h2 {
  font-size: 1.2em;
  font-weight: 900;
  text-align: left;
}

.HomeSidebarEventModal h3 {
  font-size: 0.8em;
  font-weight: 600;
}

.HomeSidebarEventModal .padding {
  padding-top: 3em;
}

.HomeSidebarEventModal .minipadding {
  padding-top: 0.5em;
}

.HomeSidebarEventModal hr {
    border: 0;
    margin: 0;
    width: 100%;
    height: 1px;
    background: #EEE;
    margin-bottom: -1em;
}

.HomeSidebarEventModal .metadata {
  color: #5F63D4;
  padding-bottom: 2em;
  text-align: right;
}

.HomeSidebarEventModal .tags {
  font-size: 1em;
  border: 2px solid #5F63D4;
  color: #000;
  border-radius: 1em;
  margin-left: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.HomeSidebarEventModal .back {
  width: 24px;
  margin: 5px;
  cursor: pointer;
  float: left;
}
