.HomeView .column {
  display: inline-block;
  height: 100vh;
  overflow-y: scroll;
  vertical-align: top;
  transition: 0.5s ease width;
}

.HomeView .left {
  background: #f9f9f9;
  padding: 6%;
  padding-top: 5%;
  width: 66%;
}

.HomeView .left.inactive {
  width: 50%;
}

.HomeView .right {
  background: #FFF;
  padding: 2.5% 4%;
  width: 34%;
}

.HomeView .right.active {
  width: 50%;
}

.HomeView .modal {
  display: none;
}

.HomeView .betaform {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #5F63D4;
  color: #FFF;
  font-size: 0.7em;
  padding: 0.6em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-align: center;
  cursor: pointer;
}

.HomeView .betaform:hover {
  background-color: #464ACC;
}

.HomeView .betaspace {
  width: 100%;
  height: 2.2em;
}

@media (max-width: 800px) {
  .HomeView .left {
    width: 100%;
  }
  .HomeView .modal {
    display: inline-block;
  }
  .HomeView .right {
    display: none;
  }
}

.HomeView .right.mobile {
  z-index: 2;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  -webkit-animation-name: hamburger-expand;
  -webkit-animation-duration: 0.5s;
  animation-name: hamburger-expand;
  animation-duration: 0.5s;
}

.HomeView .left .greybox {
  display: none;
}

.HomeView .left .greybox.active {
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #80808080;
}

@-webkit-keyframes hamburger-expand {
  from {width: 0px;}
  to {width: 300px;}
}

@keyframes hamburger-expand {
  from {width: 0px;}
  to {width: 300px;}
}
