.active .HomeSidebarCalendar {
  display: none;
  visibility: hidden;
}

.HomeSidebarCalendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 224px;
  margin: 0 auto;
}

.HomeSidebarCalendar .calendar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.HomeSidebarCalendar h2 {
  font-size: 0.8em;
  font-weight: 400;
  color: #707D93;
  letter-spacing: 0.08em;
}

.HomeSidebarCalendar .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}

.HomeSidebarCalendar .left-button {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 8px solid #D8D8D8;
  cursor: pointer;
}

.HomeSidebarCalendar .right-button {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #D8D8D8;
  cursor: pointer;
}

@media (min-width: 1400px) {
  .HomeSidebarCalendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    margin: 0 auto;
  }
}
