.AuthStatus p {
    color: #55596B;
    margin-top: 0.8em;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-align: center;
}

.AuthStatus p strong {
    font-weight: 700;
}

.AuthStatus .outline {
    color: #5e60d7;
    font-size: 0.7em;
    font-weight: 700;
    letter-spacing: 0.08em;
    border: 2px solid;
    border-radius: 50px;
    padding: 0.3em 1em;
    user-select: none;
    cursor: pointer;
}

.AuthStatus.outline:hover {
    border: 2px solid #5e60d7;
    background-color: #5e60d7;
    color: white;
}