.HomeSidebarCalendarDayItem {
  display: inline-block;
}

.HomeSidebarCalendarDayItem .circle {
  font-size: 0.7em;
  border-radius: 16px;
  border: 4px solid #FFF;
  height: 32px;
  width: 32px;
  color: #DDD;
  background-color: #FAFAFA;
  line-height: 28px;
  text-align: center;
  margin: 0em;
  cursor: pointer;
  margin-top: 0.3em;
}

.HomeSidebarCalendarDayItem .day {
  padding-top: 0.55em;
  line-height: 1.32em;
}

.HomeSidebarCalendarDayItem .tags {
  color: #BF9BD8;
  font-size: 10px;
}

.HomeSidebarCalendarDayItem.focus .circle {
  background-color: #EEEEEE;
  color: #ACB5C6;
}

.HomeSidebarCalendarDayItem.today .circle {
  background-color: #BBB;
  color: #FFF;
}

.HomeSidebarCalendarDayItem.hasEvent .circle {
  background-color: #62d65a;
  color: #fff;
}

.HomeSidebarCalendarDayItem.active .circle {
  background-color: #5F63D4;
  color: #fff;
}

.HomeSidebarCalendarDayItem .circle:hover {
  background-color: #E4E4E4;
}

.HomeSidebarCalendarDayItem.hasEvent .circle:hover {
  background-color: #33cc28;
  color: #fff;
}

.HomeSidebarCalendarDayItem.active .circle:hover {
  background-color: #464ACC;
}


@media (min-width: 1400px) {
  .HomeSidebarCalendarDayItem .circle {
    font-size: 0.9em;
    border-radius: 20px;
    border: 4px solid #FFF;
    height: 40px;
    width: 40px;
    color: #DDD;
    background-color: #FAFAFA;
    line-height: 35px;
    text-align: center;
    margin: 0em;
    cursor: pointer;
    margin-top: 0.3em;
  }

  .HomeSidebarCalendarDayItem .tags {
    color: #BF9BD8;
    font-size: 12px;
  }
}
