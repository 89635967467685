.HomeFeedEventView {
  display: flex;
  font-size: 0.75em;
  color: #55596B;
  letter-spacing: 0.08em;
  background: #F2F2F2;
  cursor: pointer;
  padding: 12px 30px;
  transition: 0.25s ease background;
  width: 92%;
  margin-bottom: 1.5em;
}

.HomeFeedEventView .left {
  all: inherit;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0px;
  float: left;
}

.HomeFeedEventView .right {
  all: inherit;
  width: 20%;
  display: flex;
  margin: 0px;
  flex-direction: column;
  align-items: center;
  float: left;

}

.HomeFeedEventView .right h3 {
  font-size: 1em;
  border: 2px solid #5F63D4;
  color: #000;
  border-radius: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-left: 0.6em;
  padding-right: 0.5em;
  text-align: center;
}



.HomeFeedEventView h2 {
  font-size: 1.2em;
}

.HomeFeedEventView.selected {
  background: #5F63D4;
  color: #FFF;
}

.HomeFeedEventView.selected:hover {
  background: #5F63D4;
  box-shadow: none;
  cursor: auto;
}

.HomeFeedEventView:hover {
  background: #FFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

.HomeFeedEventView .description {
  display: none;
}

.HomeFeedEventView .dots {
  float: right;
  font-size: 1.5em;
}

@media (max-width: 800px) {
  .HomeFeedEventView .description.selected {
    display: block;
  }

  .HomeFeedEventView.selected:hover {
    cursor: pointer;
  }
}