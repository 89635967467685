.HomeSelectionFilter {
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 30px;
}

.HomeSelectionFilter .search {
  font-family: "Nunito";
  letter-spacing: 0.1em;
  background-color: #EBEBEB;
  left: 30px;
  padding-left: 10px;
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
}

.HomeSelectionFilter .icon {
  display: grid;
  background-color: #EBEBEB;
  height: 32px;
}

.HomeSelectionFilter .icon img {
  height: 17px;
  margin: auto auto auto 5px;
}
