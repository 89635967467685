.VerifyView {
  background: #5f63d4;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  letter-spacing: 0.04em;
}

.VerifyView p {
  color: #707D93;
}

.VerifyView .logo {
  display: block;
  margin: 0 auto 40px;
  width: 250px;
}

.VerifyView .bigger-container {
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
}

.VerifyView .container {
  background: #fff;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.35);
  padding: 40px;
  max-width: 450px;
}

.VerifyView .container .code-input {
  display: table;
  margin: 0 auto;
}

.VerifyView h1 {
  color: #5f63d4;
  text-align: center;
}

.VerifyView p {
  margin-top: 24px;
  text-align: center;
}

.VerifyView input {
  border: 2px solid #e9e9e9;
  border-radius: 6px;
  display: inline-block;
  font-size: 24px;
  margin: 20px 8px 42px;
  padding: 8px 0;
  text-align: center;
  width: 40px;
  letter-spacing: 0.05em;
}

.VerifyView input::-webkit-outer-spin-button, .VerifyView input::-webkit-inner-spin-button {
  display: none;
  margin: 0;
  -webkit-appearance: none;
}

.VerifyView input[type=number] {
  -moz-appearance: textfield;
}

.VerifyView button {
  background: #5f63d4;
  border: none;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  height: 48px;
  margin: 0 auto;
  outline: none;
  width: 240px;
  letter-spacing: 0.05em;
}

.VerifyView .number-input:focus {
  outline: none;
}
