.HomeSelectionWeekBar {
  margin-top: 2em;
}

.HomeSelectionWeekBar .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.HomeSelectionWeekBar .outline {
  color: #828ca0;
  font-size: 0.7em;
  font-weight: 700;
  letter-spacing: 0.08em;
  border: 2px solid;
  border-radius: 50px;
  padding: 0.3em 1em;
  user-select: none;
  cursor: pointer;
}

.HomeSelectionWeekBar .outline:hover {
  border: 2px solid #5e60d7;
  background-color: #5e60d7;
  color: white;
}

.HomeSelectionWeekBar .weekdays {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.HomeSelectionWeekBar .weekbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.50);
  padding: 1em 2em;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.03);
}

.HomeSelectionWeekBar .weekbar:hover {
  background-color: rgba(255, 255, 255, 1);
}


.HomeSelectionWeekBar .left-button {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 10px solid #D8D8D8;
  cursor: pointer;
}

.HomeSelectionWeekBar .right-button {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid #D8D8D8;
  cursor: pointer;
}

@media (max-width: 800px) {
  .HomeSelectionWeekBar .weekbar {
    justify-content: space-around !important;
    padding: 1em 0em !important;
  }
}
